


function Blog(){
    


    return(
        <h1 style={{marginTop: "150px"}} className="txO">Em construção...</h1>
    )
}

export default Blog