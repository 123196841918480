
import RoutesApp from "./rotes"

function App(){
  return(
    <div>
      <RoutesApp/>
    </div>
  )
}

export default App