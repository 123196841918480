import { useState } from "react";

function Posts(){
    const [title, setTitle] = useState('');
    const [sub, setSub] = useState('');
    const [post, setPost] = useState([])

   function postar(e){
    e.preventDefault();
    const objec = {title, sub};
    setPost([...post, objec]);
    console.log(post);
    localStorage.setItem("@POSTS",JSON.stringify(post));
   }

    return(
        <section>
            <h1 className="txN">Faça uma nova postagem</h1><br/>
            <form onSubmit={postar}>
                <input
                placeholder="Título"
                onChange={ (e) => setTitle(e.target.value) }
                value={title}
                /><br/>
                <input
                placeholder="Subtítulo"
                onChange={ (e) => setSub(e.target.value) }
                value={sub}
                /><br/>
                <button type="submit" >PostAR</button>
            </form>
        </section>
    )
}

export default Posts