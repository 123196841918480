import Home from "./Home";
import Sobre from "./Sobre";
import "./Style/style.css";
import Contacts from "../Site/Contacts/indes";
import Footer from "../Site/Footer";

function Curso(){
    return(
        <body>
            <Home/>
            <Sobre/>
            <div className="banner"/>
            <section className="funcs">
                <h1 className="txN"><b>O que um auxiliar veterinário pode fazer?</b></h1>
                <ul>
                    <li>Receber o pet e prestar toda a assistência solicitada e orientada pelo veterinário;</li>
                    <li>Preparar o consultório e os materiais necessários para o atendimento;</li>
                    <li>Manter organizado os espaços de atendimento e também cuidar do operacional de marcações de horários;</li>
                    <li>Conter os animais agitados e prepará-los para a consulta, tratamento, exames, ou cirurgia;</li>
                    <li>Cuidar da higiene e nutrição dos animais;</li>
                    <li>Cuidar da gestão do estoque;</li>
                    <li>Fazer contatos comerciais com fornecedores e prestadores de serviços;</li>
                    <li>Atuar no atendimento e recepção da clínica, pet shop, escola e outros;</li>
                    <li>Preencher fichas e dados dos pets;</li>
                    <li>O auxiliar de veterinário poderá também cuidar temporariamente de animais de companhia;</li>
                </ul>
            </section>
            <section className="quali">
            <h1 className="txN"><b>Qualificações</b></h1>
            <div className="qualis">
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Hospitais Veterinários</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Clínicas Veterinárias</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Pet Shops</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Agropecuária</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">ONG'S</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Centro de Controle de Zoonoses</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Creches para pets</p>
                </div>
                <div className="cont">
                    <svg id="Grupo_367" data-name="Grupo 367" xmlns="http://www.w3.org/2000/svg" width="63.554" height="63.554" viewBox="0 0 63.554 63.554"><ellipse id="Elipse_40" data-name="Elipse 40" cx="31.777" cy="31.777" rx="31.777" ry="31.777" transform="translate(0)" fill="#fff"/><g id="Grupo_261" data-name="Grupo 261" transform="translate(19.585 19.924)"><path id="Caminho_751" data-name="Caminho 751" d="M639.62,5468.652c-2.628.068-3.238,2.527-5.612,4.763-3.486,3.282-2.822,6.13-.688,6.723s3.954-1.812,6.27-1.766h.043c2.317-.043,4.131,2.365,6.267,1.777s2.8-3.435-.676-6.723C642.853,5471.185,642.247,5468.725,639.62,5468.652Z" transform="translate(-627.43 -5457.033)" fill="#429fda"/><path id="Caminho_752" data-name="Caminho 752" d="M633.563,5466.992c1.452,2,1.584,4.379.294,5.314s-3.51.074-4.962-1.926-1.584-4.379-.294-5.315S632.112,5464.993,633.563,5466.992Z" transform="translate(-627.714 -5457.326)" fill="#429fda"/><path id="Caminho_753" data-name="Caminho 753" d="M645.055,5467c-1.455,2-1.591,4.376-.3,5.314s3.51.08,4.966-1.917,1.591-4.377.3-5.315S646.51,5465,645.055,5467Z" transform="translate(-626.521 -5457.325)" fill="#429fda"/><path id="Caminho_754" data-name="Caminho 754" d="M638.857,5462.441c.455,2.565-.518,4.884-2.171,5.177s-3.364-1.549-3.819-4.116.518-4.883,2.172-5.177S638.4,5459.875,638.857,5462.441Z" transform="translate(-627.341 -5457.798)" fill="#429fda"/><ellipse id="Elipse_41" data-name="Elipse 41" cx="4.718" cy="3.042" rx="4.718" ry="3.042" transform="matrix(0.176, -0.984, 0.984, 0.176, 12.046, 9.289)" fill="#429fda"/></g></svg>
                    <p className="txN">Resorts</p>
                </div>
            </div>
            </section>
            <section className="infos">
                <div className="cont">
                     <svg xmlns="http://www.w3.org/2000/svg" width="28.748" height="28.748" viewBox="0 0 28.748 28.748">
                       <path id="_6e74f5c3dc295d3d068750f075cd8b4b" data-name="6e74f5c3dc295d3d068750f075cd8b4b" d="M16.374,2A14.374,14.374,0,1,0,30.748,16.374,14.416,14.416,0,0,0,16.374,2Zm2.875,19.692a1.454,1.454,0,0,1-2.012-.575L15.08,17.093a1.3,1.3,0,0,1-.144-.719V9.187a1.437,1.437,0,0,1,2.875,0v6.756l2.012,3.737A1.591,1.591,0,0,1,19.249,21.692Z" transform="translate(-2 -2)" fill="#ff984d"/>
                     </svg>
                     <h1 className="txA">Carga Horária</h1>
                     <p className="txS">120 horas de aulas teóricas; 80 horas de aulas práticas;  6 meses de duração</p>
                     <p className="txS"><b>Aulas presenciais </b></p>
                     <p className="txS">Turmas no período da manhã e noite.</p>
                </div>
                <div className="cont">
                <svg xmlns="http://www.w3.org/2000/svg" width="24.527" height="30.657" viewBox="0 0 24.527 30.657">
                  <path id="c2b0eedccc4761c59dc63e9987216605" d="M16.263,2A12.277,12.277,0,0,0,4,14.263c0,4.99,3.622,9.133,7.459,13.519,1.214,1.389,2.471,2.825,3.59,4.278a1.533,1.533,0,0,0,2.428,0c1.119-1.453,2.376-2.89,3.59-4.278,3.837-4.386,7.459-8.529,7.459-13.519A12.277,12.277,0,0,0,16.263,2Zm0,16.862a4.6,4.6,0,1,1,4.6-4.6A4.6,4.6,0,0,1,16.263,18.862Z" transform="translate(-4 -2)" fill="#ff984d"/>
                </svg>
                     <h1 className="txA">Local do curso</h1>
                     <p className="txS">Av. Nordestina, 581S. Miguel Pta, São Paulo, SP</p>
                     <a href="https://goo.gl/maps/kGZcjtwUmGgWFnKD6"><button className="butO">Av. Nordestina, 581S. Miguel Pta, São Paulo, SP</button></a>
                </div>
            </section>
            <Contacts/>
            <Footer/>
        </body>
    )
}
export default Curso;