import {Swiper, SwiperSlide} from 'swiper/react';import { Navigation, Pagination, Keyboard, Autoplay} from 'swiper';import 'swiper/css';import 'swiper/css/navigation';import 'bootstrap/dist/css/bootstrap.min.css'
import stars from "../../../assets/ICONES/estrelas-depoimentos.svg";

function Depo(){
    
    return(
        <section id='depoiment'>
        <h1 className='txN'>Depoimentos de nossos clientes</h1>
        <p className='txN'>Veja o que os nossos clientes tem a dizer sobre o HOVETSP</p>
        <Swiper
            id="swiperDeps"
            modules={[Navigation, Pagination, Keyboard, Autoplay, ]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{running: true,  timeLeft: 500, }}
            breakpoints={{
                800: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
          >
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Vanessa Antunes</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Hospital Veterinário super bem preparado para realização de exames, internação e todos os cuidados que nossos animais merecem. Quero indicar e agradecer a Dra. Bárbara Bastos pelo carinho e competência com minha tapioca e também as recepcionistas que são muito atenciosas, me sinto acolhida e bem atendida em todos os sentidos. Recomendo!</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Gislaine Dutra</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Sempre foi uma experiência  maravilhosa  passar a nossa *Bebel* neste hospital que é um local limpo, recepcionistas  educadas e profissionais  qualificados!<br></br>Em todas as necessidades  que já tivemos com nossa filha de 4 patas os profissionais prontamente nos atendeu com  um excelente conhecimento!</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Arlete Arlete</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Excelente atendimento!!!! Clinica da Oliveira Freire 884. Começando pela recepção.  Veterinária Raissa Okamura. Profissional que gosta do que faz. Passo meu cachorrinho Balu somente com ela. Faço questão que seja com ela. Extremamente atenciosa com meu cachorrinho. Recomendo para todos que eu conheço..vale a pena. Espero que ela continue por muito tempo nesta clínica.</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Juliane Moraes</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Ja levei meus 4 gatinhos e minha cachorra… 🥰 atendimento bom, doutora Waleska cuidou super bem da amora, é uma doutora incrível, super indico! …</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Barbara Feliciano</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'> Excelente salvarão, meu cachorro que chegou com a vida por fio ,super atenciosos e Rapidos , prontidão em emergência excelente obrigada 👏👏👏🙏🏼 …</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Rosana Rodrigues</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Minha experiência foi gratificante,Minha cachorrinha de 8 anos fez cirurgia de Perimetral,e foi bem assistida ,bem tratada excelente equipe ,a Cirurgia Dr Julia muito carinhosa ,hj minha mocinha passa bem agradeço o carinho de TDS da equipe em especial Dr Julia.Meu muito obrigado eu recomendo.</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Fernando Flores</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Gostei muito do atendimento ,local muito limpo , atendimento da recepção bem rápido , ótimos   profissionais,fui levar minha filha de 4 patas pra realizar ultrassom e exames de análises clínicas , resultados bem rápidos ,os preços tbem  em vista a outros locais é bem em conta.</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Rita de Cassia</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Precisei de um especialista para minha cachorrinha e o oftalmo se prontificou a atende la  e a atendeu maravilhosamente bem , atencioso e sempre tirando todas nossas duvidas com paciência carinho e atencao , parabéns a toda equipe pela educação e cordialidade ♡♡♡♡</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>João Cezar</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Ótimo atendimento, ótimo custo x benefício! Profissionais atenciosos, educados e cuidadosos!  Dr Camila é muito gente boa... Parabéns e obrigado por cuidar do Marley! Unidade da Av Nordestina</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Rosana Rodrigues</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'> Minha experiência foi gratificante,Minha cachorrinha de 8 anos fez cirurgia de Perimetral,e foi bem assistida ,bem tratada excelente equipe ,a Cirurgia Dr Julia muito carinhosa ,hj minha mocinha passa bem agradeço o carinho de TDS da equipe em especial Dr Julia.Meu muito obrigado eu recomendo.</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Fernando Flores</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Gostei muito do atendimento ,local muito limpo , atendimento da recepção bem rápido , ótimos   profissionais,fui levar minha filha de 4 patas pra realizar ultrassom e exames de análises clínicas , resultados bem rápidos ,os preços tbem  em vista a outros locais é bem em conta.</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Rita de Cassia</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Precisei de um especialista para minha cachorrinha e o oftalmo se prontificou a atende la  e a atendeu maravilhosamente bem , atencioso e sempre tirando todas nossas duvidas com paciência carinho e atencao , parabéns a toda equipe pela educação e cordialidade ♡♡♡♡</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>João Cezar</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Ótimo atendimento, ótimo custo x benefício! Profissionais atenciosos, educados e cuidadosos!  Dr Camila é muito gente boa... Parabéns e obrigado por cuidar do Marley! Unidade da Av Nordestina</p>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide' >
                <div className='cont'>
                <h2 className='txA' ><b>Aline Albertini</b></h2>
                <img src={stars} img=""/>
                </div>
                <p className='txB'>Super indico,meu bebê foi operado pela Dra Júlia, fui até ao hospital apenas para realizar um ultrassom que fui encaminhada pela outra clínica que meu pet estava internado e por lá mesmo fiquei,optei por entregar a vida do Snoopy a ela,me passaram muita confiança  desde a recepção até a doutora que o operou. Hoje faz 5 dias que operou e está ótimo. Obrigada Hospital Veterinário.
</p>
            </SwiperSlide>
        </Swiper>
        </section>
    )
}

export default Depo;