function Contacts(){
    return(
        <section id='contact' >
        <div className='content'>
            <div className='cont'>
                <h2 className='txO'>Nos envie uma mensagem!</h2>
                <p className='txN'>Entre em contato, tire suas dúvidas ou agende consultas e exames usando nosso formulário de contato.</p>
            </div>
            <div className='form'>
                <input placeholder='Nome:' />
                <input placeholder='Whatsapp:' />
                <input placeholder='Mensagem:' />
                <button className='butO' >Enviar</button>
            </div>
        </div>  
</section>
    )
}
export default Contacts;