import hospital from "../../assets/IMAGENS/Ativo 1.png";import cstSer from "../../assets/IMAGENS/Grupo 563.png";import dogSer from "../../assets/IMAGENS/Retângulo 64.png";import checup from "../../assets/IMAGENS/Grupo 562.jpg";import pharme from "../../assets/ICONES/icone-farmacia.svg";

import Proced from './Swipe';
import Depo from './Depo';
import Contacts from "./Contacts/indes";
import Footer from "./Footer";
import Creds from "./Creds";
import Onde from "./Onde";

import { Link } from 'react-router-dom';


function SiteBody(){

    function test(e){
        alert(`test: ${e}`)
    }

    return(
        <body>
            <section className="sec" id="home">
                <strong className="txN">O melhor cuidado para quem é parte da família!</strong>
                <h2 className="txN">HOVETSP: o Hospital Veterinário mais seguro e completo de São Paulo.</h2>
                <a href="https://app.gprede.com.br/google/1678292563419x147195819704975360" target="_blank"><button class="butO">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 19">
                  <g id="Grupo_57" data-name="Grupo 57" transform="translate(0 0)">
                    <path id="Caminho_45" data-name="Caminho 45" d="M164.858,542.3c-2.2.057-2.716,2.12-4.709,4-2.923,2.753-2.367,5.143-.577,5.639s3.316-1.521,5.26-1.481h.037c1.944-.036,3.465,1.984,5.257,1.49s2.353-2.881-.566-5.64C167.57,544.425,167.063,542.361,164.858,542.3Z" transform="translate(-154.632 -532.554)" fill="#f3f3f3"/>
                    <path id="Caminho_46" data-name="Caminho 46" d="M159.778,540.908c1.218,1.677,1.328,3.674.247,4.459s-2.946.062-4.163-1.615-1.328-3.675-.247-4.46S158.56,539.231,159.778,540.908Z" transform="translate(-154.87 -532.799)" fill="#f3f3f3"/>
                    <path id="Caminho_47" data-name="Caminho 47" d="M169.418,540.917c-1.221,1.675-1.336,3.672-.256,4.459s2.946.067,4.167-1.609,1.335-3.672.255-4.458S170.639,539.241,169.418,540.917Z" transform="translate(-153.869 -532.798)" fill="#f3f3f3"/>
                    <path id="Caminho_48" data-name="Caminho 48" d="M164.218,537.091c.381,2.152-.435,4.1-1.822,4.343s-2.822-1.3-3.2-3.452.435-4.1,1.822-4.343S163.837,534.938,164.218,537.091Z" transform="translate(-154.558 -533.195)" fill="#f3f3f3"/>
                    <ellipse id="Elipse_4" data-name="Elipse 4" cx="3.958" cy="2.552" rx="3.958" ry="2.552" transform="matrix(0.176, -0.984, 0.984, 0.176, 10.105, 7.793)" fill="#f3f3f3"/>
                  </g>
                </svg>
                  Fale com a gente</button></a>
            </section>
            <section id="hospital">
                <div className="cont">
                <h1 className="txW">O Hospital Veterinário</h1>
                <p className="txW">O hospital foi fundado em 2015, está localizado na zona leste de São Paulo, em São Miguel Paulista e se destaca por ter uma das melhores estruturas da região.<br/><br/>Com estrutura completa, o hospital oferece um atendimento integrado de prevenção, diagnóstico e tratamento ao animal de estimação, aberto <b>24 horas por dia, todos os dias do ano.</b><br/><br/>Contemplamos com uma área de internação para cães, distinta da internação de gatos, com acomodação em leito individual. Para os animais que apresentam alguma doença infectocontagiosa, existe uma área de isolamento reservada ao tratamento e segurança dos demais animais internados. <br/><br/>Exames laboratoriais, radiologia, ultrassom, eletrocardiograma e ecocardiograma podem ser realizados no local.<br/><br/>Com abordagem interdisciplinar, são mais de 10 especialidades médicas veterinárias disponíveis na equipe de especialistas.
</p>
                </div>
                <img src={hospital} alt="foto hospital"/>
            </section>
            <section id="services">
                <div className="bolth">
                <div className="service ">
                    <img src={cstSer} alt=""/>
                    <div className="cont emer">
                        <h2 className="txW">EMERGÊNCIA 24h</h2>
                        <p className="txW">Estamos aqui para você e seu pet 24 horas por dia, 7 dias por semana, prontos para oferecer um atendimento de qualidade.</p>
                    </div>
                </div>
                <div className="service ">
                    <img src={dogSer} alt=""/>
                    <div className="cont taxi">
                        <h2 className="txW">TÁXI PET</h2>
                        <p className="txW">Seu animalzinho consegue chegar até o HOVETSP com muita segurança e conforto.</p>
                    </div>
                </div>
                </div>
                <a href="https://app.gprede.com.br/google/1678292563419x147195819704975360" target="_blank"><button className="butO">Solicitar serviços</button></a>
            </section>
            <section id="banner1"/>
            <Proced/>
            <section id="check-up">
              <img src={checup} img=""/>
              <div className='cont'>
                <h1 className='txW'>Quando foi a última vez que seu pet fez check-up?</h1>
                <p className='txW'>Realizar exames preventivos de forma regular é uma ótima forma de manter seu cão ou gato saudável e prevenir possíveis doenças. Se você ama seu bichinho, cuide bem dele com exames de prevenção e garanta que ele tenha uma vida longa e saudável.</p>
                <div className='btns'>
                    <a href='https://app.gprede.com.br/google/1678292563419x147195819704975360' target="_blank" ><button className='butT' >Agendar check-up</button></a>
                    <a href='https://vejasp.abril.com.br/coluna/bichos/check-up-animais-estimacao' target="_blank" ><button className='butT' >Saber mais</button></a>
                </div>
              </div>
            </section>
            <section id='pharm'>
        <div className='cont'>
            <h1 className='txN'>Conheça nossa Farmácia Veterinária</h1>
            <h3 className='txN' >Aberta 24 horas, visando o conforto e a tranquilidade dos tutores.</h3>
            <p className='txN'>Nossa farmácia tem parcerias com os mais conceituados laboratórios veterinários para oferecer ao seu pet o melhor em medicamentos e tratamentos. Estamos comprometidos em garantir que seu animal de estimação receba as melhores soluções para suas necessidades clínicas, assegurando, assim, o êxito do tratamento recomendado.</p>
            <a href="https://app.gprede.com.br/google/1678292563419x147195819704975360" target='_blenk'><button className='butO' >Quero saber mais</button></a>
        </div>
        <img src={pharme} alt=""/>
            </section>
            <section id='curso'>
        <div className='cont'>
            <h3 className='txW'>NOSSO CURSO</h3>
            <h1 className='txN'><b>Transforme sua paixão em profissão! </b> Seja um Auxiliar Veterinário!</h1>
            <p className='txN'>Quer saber como cuidar melhor do seu bichinho, ingressar no mercado ou descobrir se a veterinária é para você? <b> Clique no botão e saiba mais!</b></p>
            <Link to="/curso"><button className='butW'>Saiba mais</button></Link>
        </div>
            </section>
            <Depo/>
            <Onde/>
            <Contacts/>
            <Footer/>
            <Creds/>
        </body>
    )
}

export default SiteBody;