import insta from "../../../assets/ICONES/instagram.svg";import face from "../../../assets/ICONES/facebook.svg";import logo from "../../../assets/ICONES/logo-rodape.svg"

function Footer(){
    return(
        <footer>
        <div className='omt'>
            <p className='txB'><b>CONTATO</b></p>
            <p className='txN'>contato@hovetsp.com.br</p>
            <a href="https://www.instagram.com/hovetsp/" target="_blank" ><img src={insta} alt=""/></a>
            <a href="https://www.facebook.com/hospitalveterinariosaopaulo/" target="_blank" ><img src={face} alt=""/></a>
        </div>
        <div>
            <img src={logo} alt=""/>
        </div>
        <div>
            <p className='txB'><b>FUNCIONAMENTO</b></p>
            <p className='txN'>Aberto 24h para<br/>atender ao seu pet.</p>
        </div>
    </footer>
    )
}

export default Footer;