import { useState } from "react";
import seta from "../../../assets/curso/ICONES/seta.svg"
function Sobre(){
      const [curs, setCurs] = useState(false)
      const [curs1, setCurs1] = useState(false)
      const [curs2, setCurs2] = useState(false)
      const [curs3, setCurs3] = useState(false)
      const [curs4, setCurs4] = useState(false)
      const [curs5, setCurs5] = useState(false)
    function openMat(e){
        if(curs === false){
            setCurs(true)
        }else{
            setCurs(false)
        }
    }
    function openMat1(e){
        if(curs1 === false){
            setCurs1(true)
        }else{
            setCurs1(false)
        }
    }
    function openMat2(e){
        if(curs2 === false){
            setCurs2(true)
        }else{
            setCurs2(false)
        }
    }
    function openMat3(e){
        if(curs3 === false){
            setCurs3(true)
        }else{
            setCurs3(false)
        }
    }
    function openMat4(e){
        if(curs4 === false){
            setCurs4(true)
        }else{
            setCurs4(false)
        }
    }
    function openMat5(e){
        if(curs5 === false){
            setCurs5(true)
        }else{
            setCurs5(false)
        }
    }
    return(
        <section id="sobre">
            <div className="box">
            <div className="cont">
                <h1 className="txN"><b>Sobre o curso</b></h1> 
                <p className="txA"><b>Você sonha em trabalhar com animais? Seja um Auxiliar Veterinário!</b></p>
                <p className="txS">Aprenda as habilidades necessárias para se tornar um profissional experiente no campo da medicina veterinária e faça parte desta profissão gratificante. O nosso curso de auxiliar veterinário oferece tudo o que você precisa saber para iniciar a sua carreira na área: desde cuidados básicos até conhecimentos avançados sobre diagnósticos, tratamentos e procedimentos cirúrgicos. Com o nosso treinamento, você aprenderá a lidar com diferentes tipos de animais, monitorando sinais vitais, administrando medicação e realizando exames clínicos. Além disso, nossas orientações te ensinarão também sobre gestão clínica e financeira dos estabelecimentos veterinários. Inscreva-se agora mesmo no nosso curso de Auxiliar Veterinário para começar a construir sua carreira neste ramo! Não perca esta chance!</p>
           </div>
           <button className="butO">Me inscrever agora</button>
           <h1 className="txN"><b>O que você irá aprender?</b></h1> 
           <p className="txA"><b>Veja tudo sobre o conteúdo programático do curso</b></p>
           <div className="cursos">
                <div className="content" data-active={curs} onClick={()=> openMat()}>
                    <h5 className="txA">Introdução Auxiliar Veterinário</h5>
                    <img id="seta" src={seta} alt=""/>
                    <ul>
                        <li>Atendimento ao cliente e relações interpessoais</li>
                        <li>Bem-estar animal e Legislação </li>
                        <li>Etologia canina e felina</li>
                        <li>Introdução à anatomia</li>
                        <li> Morfologia animal</li>
                    </ul>
                </div>
                <div className="content" data-active={curs1} onClick={()=> openMat1()}>
                    <h5 className="txA">Fisiologia</h5>
                    <img id="seta" src={seta} alt=""/>
                    <ul>
                        <li>Hematologia</li>
                        <li>Sistema circulatório</li>
                        <li>Sistema dermatológico</li>
                        <li>Sistema digestório</li>
                        <li>Sistema endócrino</li>
                        <li>Sistema linfático</li>
                        <li>Sistema musculoesquelético</li>
                        <li>Sistema nervoso</li>
                        <li>Sistema reprodutor</li>
                        <li>Sistema Urinário</li>
                    </ul>
                </div>
                <div className="content" data-active={curs2} onClick={()=> openMat2()}>
                    <h5 className="txA">Prática Clínica</h5>
                    <img id="seta" src={seta} alt=""/>
                    <ul>
                        <li>Biossegurança (teórica e prática)</li>
                        <li>Contenção (teórica e prática)</li>
                        <li>Emergência e internação</li>
                        <li>Prática de coleta e exames laboratoriais</li>
                        <li>Primeiros socorros + prática de primeiros socorros</li>
                    </ul>
                </div>
                <div className="content" data-active={curs3} onClick={()=> openMat3()}>
                    <h5 className="txA">Manejo</h5>
                    <img id="seta" src={seta} alt=""/>
                    <ul>
                        <li>Cirurgia</li>
                        <li>Coleta</li>
                        <li>Controle de endo e ectoparasitas</li>
                        <li>Emergência e internação</li>
                        <li>Exame Clínico</li>
                        <li>Exames laboratoriais em cães e gatos</li>
                        <li>Farmacologia</li>
                        <li>Fluidoterapia</li>
                        <li>Higiene</li>
                        <li>Manejo de feridas</li>
                        <li>Nutrição</li>
                        <li>Vacinologia</li>
                    </ul>
                </div>
                <div className="content" data-active={curs4} onClick={()=> openMat4()}>
                    <h5 className="txA">Políticas Públicas em Saúde Veterinária</h5>
                    <img id="seta" src={seta} alt=""/>
                    <ul>
                        <li>Código de defesa do consumidor</li>
                        <li>Seminários zoonoses</li>
                    </ul>
                </div>
                <div className="content" data-active={curs5} onClick={()=> openMat5()}>
                    <h5 className="txA">Trabalho de Conclusão de Curso</h5>
                    <img id="seta" src={seta} alt=""/>
                    <ul>
                        <li>O trabalho de conclusão do curso de auxiliar veterinário é um documento que avalia o aprendizado e as habilidades adquiridas pelo aluno durante a duração do curso. O trabalho tem como objetivo demonstrar sua capacidade de pesquisa, análise crítica e síntese sobre assuntos relacionados à área da saúde animal.</li>
                    </ul>
                </div>
           </div>
           </div>
        </section>
    )
}

export default Sobre;