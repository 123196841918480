import ciruegia from "../../../assets/ICONES/cirurgias.svg"; import clinicaGeral from "../../../assets/ICONES/clinica-geral.svg"; import dermatologia from "../../../assets/ICONES/dermatologia.svg"; import exame from "../../../assets/ICONES/exames-de-imagens.svg"; import fisioterapia from "../../../assets/ICONES/fisioterapia.svg"; import internacao from "../../../assets/ICONES/internacao.svg"; import neurologia from "../../../assets/ICONES/neurologia.svg"; import nutricao from "../../../assets/ICONES/nutricao-clinica.svg"; import odontologia from "../../../assets/ICONES/odontologia.svg"; import oxigenoterapia from "../../../assets/ICONES/oxigenoterapia.svg"; import vacinacao from "../../../assets/ICONES/vacinacao.svg";
import {Swiper, SwiperSlide} from 'swiper/react';import { Navigation, Pagination, Keyboard, Autoplay} from 'swiper';import 'swiper/css';import 'swiper/css/navigation';import 'bootstrap/dist/css/bootstrap.min.css'
import { useState } from "react";




function Proced(){

    function Modal({isOpen}){
        if(isOpen){
            return(
                <div id="popup" datatype="false">
                    <div className="cont">
                    <h1 className="txA" >{tit}</h1>
                    <p>{tex}</p>
                    <button className="butO" onClick={()=> closePop()} >Fechar</button>
                    </div>
            </div>
            )
        }
         return null
    }

    const [tit, setTit] = useState("");
    const [tex, setTex] = useState("");
    const [modal, setModal] = useState(false)
    function openPup(props){
        
      setTex(props.tx)
      setTit(props.ti)
      setModal(true)
      
    }

    function closePop(){
        setModal(false)
    }

return(
        <section id="swiper">
        <h1 className="txN">Atendimento de qualidade para seu Pet</h1>
        <p className="txN">Dedicado à saúde total para animais de estimação, o Hospital Veterinário São Paulo possui uma linha completa para todos os casos.</p>
        <Swiper
            id="swiperServ"
            modules={[Navigation, Pagination, Keyboard, Autoplay, ]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{running: true,  timeLeft: 500, }}
            breakpoints={{
                800: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
          >
            <SwiperSlide onClick={()=> openPup({ ti:"CIRURGIAS", tx: "O HOVETSP possui um centro cirúrgico com os equipamentos mais avançados para garantir a segurança de seus pacientes durante os procedimentos cirúrgicos. No Hospital são realizados procedimentos das mais variadas naturezas: Sistema reprodutivo, cardíaco, digestivo, urinário, respiratório, cutâneo, oftálmico e otológico, bem como cesarianas, luxações e fraturas." })} className='swiper-slide' >
                <div className="img"><img src={ciruegia} alt="img"/></div>
                <h3 className='txB' >CIRURGIAS</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"CLÍNICA GERAL", tx: "Ele vai cuidar da saúde geral e ter todo o histórico do seu animal. O médico veterinário clínico geral está apto a cuidar do seu animal mesmo antes do nascimento, dando atenção especial à saúde e nutrição da mãe, auxiliando no parto quando necessário e, por fim, verificando a saúde de cada um dos filhotes." })} className='swiper-slide' >
                <div className="img"><img src={clinicaGeral} alt="img"/></div>
                <h3 className='txB' >CLÍNICA GERAL</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"DERMATOLOGIA", tx: "A Dermatologia Veterinária ocupa-se do diagnóstico e tratamento clínico-cirúrgico das doenças de pele nos animais. A especialidade atua em todos os processos fisiopatológicos que envolvem a pele, como: infecções, reações auto-imunes, reações inflamatórias e tumores." })} className='swiper-slide' >
                <div className="img"><img src={dermatologia} alt="img"/></div>
                <h3 className='txB' >DERMATOLOGIA</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"EXAMES DE IMAGEM", tx: "Os exames de imagem para cães e gatos incluem Ultrassonografia, Radiologia e Eletrocardiograma, que permitem avaliar o funcionamento orgânico do animal de forma precisa e não invasiva. Estes exames são capazes de identificar problemas cardíacos, pulmonares, digestivos, urinários e genitais." })} className='swiper-slide' >
                <div className="img"><img src={exame} alt="img"/></div>
                <h3 className='txB' >EXAMES DE IMAGEM</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"FISIOTERAPIA", tx: "A fisioterapia veterinária e seus benefícios vão desde uma recuperação veloz de uma cirurgia até animais que conseguiram voltar a andar depois de fortes traumas. A fisioterapia animal vem proporcionando conforto e uma opção eficiente de recuperação, conservando musculatura, evitando novas intervenções cirúrgicas, aumentando expectativa e qualidade de vida dos pets." })} className='swiper-slide' >
                <div className="img"><img src={fisioterapia} alt="img"/></div>
                <h3 className='txB' >FISIOTERAPIA</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"INTERNAÇÃO", tx: "Quando os animais chegam para atendimento inicial, seja em situações de rotina ou emergência, contam com um setor de internação equipado com leitos individuais. Os pacientes são monitorados 24 horas por dia por médicos-veterinários e enfermeiros, e recebem cuidados especializados: medicamentos, alimentação adequada às suas necessidades nutricionais, aquecimento conforme a temperatura da região e banho de sol diariamente. Além disso, todos os leitos passam por uma rigorosa limpeza e esterilização diariamente para garantir a saúde dos nossos pacientes." })} className='swiper-slide' >
                <div className="img"><img src={internacao} alt="img"/></div>
                <h3 className='txB' >INTERNAÇÃO</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"NEUROLOGIA", tx: "Aqui é estudado e analisado todo o sistema nervoso dos pets, desde cérebro, cerebelo, medula espinhal, tronco encefálico e sistema nervoso periférico (desde raízes nervosas, nervos e junções neuromusculares), realizando avaliações profissionais e indicando os tratamentos mais adequados para cada patologia." })} className='swiper-slide' >
                <div className="img"><img src={neurologia} alt="img"/></div>
                <h3 className='txB' >NEUROLOGIA</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"NUTRIÇÃO CLÍNICA", tx: "Avaliamos as necessidades nutricionais do seu pet e selecionamos um alimento adequado às suas condições específicas. Em seguida, são prescritos os níveis apropriados de nutrientes e é estabelecido um plano de alimentação que atenda às necessidades nutricionais individuais do animal." })} className='swiper-slide' >
                <div className="img"><img src={nutricao} alt="img"/></div>
                <h3 className='txB' >NUTRIÇÃO CLÍNICA</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"ODONTOLOGIA CANINA", tx: "Esta especialidade dedica sua atenção ao estudo da anatomia, fisiologia, patologia e terapia para as afecções que atingem a cavidade oral dos animais. Doenças odontológicas consideradas mais comuns que atingem os pets, estão relacionadas à inflamação da gengiva e dos tecidos que suportam os dentes.As doenças odontológicas consideradas mais comuns que atingem os pets estão relacionadas à inflamação da gengiva e dos tecidos que suportam os dentes" })} className='swiper-slide' >
                <div className="img"><img src={odontologia} alt="img"/></div>
                <h3 className='txB' >ODONTOLOGIA CANINA</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"OXIGENOTERAPIA", tx: "A oxigenoterapia é uma forma eficaz de tratamento para diversas doenças em animais. O procedimento envolve o paciente veterinário inalando oxigênio puro a cem por cento dentro de uma câmara hermeticamente fechada com paredes rígidas, sob pressão entre uma e três atmosferas. Esta pressão extra possibilita que o combustível essencial para regenerar tecidos, o oxigênio, chegue mais facilmente à área desejada para acelerar sua recuperação" })} className='swiper-slide' >
                <div className="img"><img src={oxigenoterapia} alt="img"/></div>
                <h3 className='txB' >OXIGENOTERAPIA</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
            <SwiperSlide onClick={()=> openPup({ ti:"VACINAÇÃO", tx: "Sabemos que a saúde e o bem-estar dos Pets estão estritamente ligados à sua vacinação. Por isso, precisamos manter a vacinação em dia para que nenhuma enfermidade os acometam e coloque em risco suas vidas. Pensando nisso, o HOVETSP oferece uma linha completa de vacinas importadas para a proteção de seus pacientes." })} className='swiper-slide' >
                <div className="img"><img src={vacinacao} alt="img"/></div>
                <h3 className='txB' >VACINAÇÃO</h3>
                <p>Saiba mais</p>
            </SwiperSlide>
        </Swiper>
        <Modal isOpen={modal}/>
        </section>
)}

export default Proced;