import { useState } from "react"

function Home(){
    const [name, setName] = useState("")
    const [number, setNumber] = useState("")
    const [cad, setCad] = useState([])
    function cadastrar(e){
        setCad([...cad, {number, name}])
        e.preventDefault();
        localStorage.setItem("@cadaster",JSON.stringify(cad))
    }
    return(
        <section id='homeCurse'>
            <div className="cont">
                <h1 className="txN"><b>Transforme sua paixão em profissão!</b> Seja um Auxiliar Veterinário!</h1>
                <p className="txN">Aprenda tudo o que precisa para cuidar dos nossos melhores amigos e se torne um profissional habilitado!</p>
                <form onSubmit={cadastrar}>
                    <input
                    placeholder="Escreva seu nome completo:"
                    value={name}
                    onChange={(e)=> setName(e.target.value)}
                    />
                    <input
                    placeholder="Deixe seu número para contato:"
                    value={number}
                    onChange={(e)=> setNumber(e.target.value)}
                    />
                    <button className="butO" type="submit">Enviar</button>
                </form>
            </div>
        </section>
    )
}
export default Home