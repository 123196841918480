import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./header";
import SiteBody from "./pages/Site/indes";
import Posts from "./pages/Posts";
import Blog from "./pages/Blog";
import Curso from "./pages/Curso/indes";
function RoutesApp(){
    return(
        <BrowserRouter basename="/" >
            <Header/>
            <Routes>
                <Route path="/" element={<SiteBody/>}/>
                <Route path="/posts" element={<Posts/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/curso" element={<Curso/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp;