import gpr from "../../../assets/ICONES/GPRlogo.svg";
import herath from "../../../assets/ICONES/herth.svg";
import "./index.css"

function Creds(){
    return(
        <div id="creds">
            <div className="fut">
            <div className="cont">
                <span className="futtx">©2023 | Feito com <img src={herath} alt=""/>  por:</span>
                <a id="link" target="_blank" href="https://gprede.com.br/"  ><img src={gpr} alt=""/></a>
            </div>
            <span className="txd">Todos os dados informados neste site estão protegidos pela LGPD, Lei nº 13.709/2018.</span>
        
    
            </div>
        </div>
    )
}

export default Creds