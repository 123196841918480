import { useState } from "react";
import "./style.css"
import seta from "../../../assets/curso/ICONES/seta.svg"
function Onde(){
      const [curs, setCurs] = useState(false)
      const [curs1, setCurs1] = useState(false)
    function openMat(e){
        if(curs === false){
            setCurs(true)
        }else{
            setCurs(false)
        }
    }
    function openMat1(e){
        if(curs1 === false){
            setCurs1(true)
        }else{
            setCurs1(false)
        }
    }
    return(
        <section id="onde">
           <h1 className="txN"><b>ONDE NOS ENCONTRAR?</b></h1> 
           <p className="txS">Aqui, você e seu pet encontrarão médicos veterinários, assistentes e colaboradores atenciosos e comprometidos com o melhor atendimento.</p>
           <div className="block">
                <div className="content" data-active={curs} onClick={()=> openMat()}>
                    <h5 className="txA">Hospital Veterinário <img id="seta" src={seta} alt=""/></h5> 
                    <div>
                       <p className="txS">Av. Nordestina, 581 - Vila Americana, São Paulo - SP, 08021-000</p><br/>
                       <div className="numbs">
                             <a href="tel:1125330000" target="_blenk"><p className="txA" ><b>(11) 2533-0000 </b></p></a>
                             <a href="tel:1125370000" target="_blenk"><p className="txA" ><b>(11) 2537-0000</b></p></a>
                             <a href="tel:11945295304" target="_blenk"><p className="txA" ><b>(11) 9 4529-5304</b></p></a>
                       </div>
                    </div>
                </div>
                <div className="content" data-active={curs1} onClick={()=> openMat1()}>
                    <h5 className="txA">Centro Veterinário <img id="seta" src={seta} alt=""/></h5>
                    <div>
                       <p className="txS">Av. Oliveira Freire, 884 - Parque Paulistano, São Paulo - SP, 08080-570</p><br/>
                       <a href="tel:1125812900" target="_blenk"><p className="txA" ><b>(11) 2581-2900</b></p></a>
                    </div>
                </div>
               
           </div>
        </section>
    )
}

export default Onde;